import React, { Component } from "react";
import bannerBg from '../../assets/img/422-bg.jpg';
import "./UserBar.css";

class UserBar extends Component {
  render() {
    return (
      <div className="userBarSec">
        <div className="callSupportSec">
          <a className="btn" href="tel:6103236253">610.323.6253</a>
        </div>
        <div className="userREgLoginSec">
        <form className="form-inline">
        <div className="form-check mb-2 mr-sm-2">
    <label className="form-check-label" >
      <a href="javascript:void(0);">SignUp</a> 
      <a href="javascript:void(0);">Login</a> 
    </label>
  </div>
              <div className="input-group mb-3">
        <div className="input-group-prepend">
          <select>
            <option>Site</option>
            <option>User</option>
          </select>
        </div>
        <input type="text" className="form-control"/>
      </div>
        </form>
        </div>
      </div>
    );
  }
}

export default UserBar;
