import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import { graphql } from "gatsby";
// import PostListing from "../components/PostListing/PostListing";
// import SEO from "../components/SEO/SEO";
// import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import config from "../../data/SiteConfig";
import BannerHome from "../components/BannerHome/BannerHome";
import Footer from "../components/Footer/Footer";
import ServingRoute from "../components/ServingRoute/ServingRoute";
import Contributors from "../components/Contributors/Contributors";
import RecentActivity from "../components/RecentActivity/RecentActivity";
import UserBar from "../components/UserBar/UserBar";

class Index  extends React.Component {
  render() {
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle} />
          {/* <SEO /> */}
          <Header />
          <BannerHome />
          <UserBar />
          <div className="container">
          <div class="row justify-content-between">
            <div class="col-12">
              <ServingRoute />
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="col-4">
              <Contributors />
            </div>
            <div class="col-4">
              <RecentActivity />
            </div>
          </div>
          </div>
          <Footer />
        </div>
      </Layout>
    );
  }
}

export default Index;
