import React, { Component } from "react";
import logo from '../../assets/img/logo.png';
import "./Header.css";

class Header extends Component {
  render() {
    return (
      <header>
        <section className="container">
        <nav className="navbar navbar-expand-lg">
  <a className="navbar-brand" href="/"><img src={logo} alt="Business Advisor"/></a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>

  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <ul className="navbar-nav ml-auto">
      <li><a className="active" href="/">Home</a></li>
      <li><a href="javascript:void(0);">Experts</a></li>
      <li><a href="javascript:void(0);">Companies</a></li>
      <li><a href="javascript:void(0);">Organizations</a></li>
      <li><a href="javascript:void(0);">Events</a></li>
      <li><a href="javascript:void(0);">Search</a></li>
    </ul>
  </div>
</nav>
        </section>
      </header>
    );
  }
}

export default Header;
