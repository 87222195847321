import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import "./RecentActivity.css";

export default () => (
  <StaticQuery
    query={graphql`
      query AllActivitiesQuery {
        allActivities {
          edges {
            node {
              text
            }
          }
        }
      }
    `}
    render={data => (
      <div className="card">
      <div className="siteActivitySec">
        <h6 className="headingTitle">Recent site activity</h6>
        <div className="siteActivityListSec">
          <ul>
          {data.allActivities.edges.map((activity, i) => {
              const userData = activity.node;
              return (
                <li key={i} dangerouslySetInnerHTML={{__html: userData.text }}></li> 
              )
            })}
          </ul>
        </div>
      </div>
      </div>
    )}
  />
)

