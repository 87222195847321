import React, { Component } from "react";
import bannerBg from '../../assets/img/422-bg.jpg';
import "./BannerHome.css";

class BannerHome extends Component {
  render() {
    return (
      <div className="bannerHome">
        <img src={bannerBg} alt="banner"/>
      </div>
    );
  }
}

export default BannerHome;
