import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby"
import "./Contributors.css";

export default () => (
  <StaticQuery
    query={graphql`
      query AllUserQuery {
        allDummyUsers {
          edges {
            node {
              picture
            }
          }
        }
      }
    `}
    render={data => (
      <div className="card">
      <div className="contributorsSec">
        <h6 className="headingTitle">Contributors</h6>
        <div className="contributorsAvatarSec">
          <ul>
            {data.allDummyUsers.edges.map((user, i) => {
              const userData = user.node;
              return (
                <li key={i} dangerouslySetInnerHTML={{__html: userData.picture }}></li>  
              )
            })}
          </ul>
        </div>
      </div>
      </div>
    )}
  />
)

