import React, { Component } from "react";
import "./ServingRoute.css";

class ServingRoute extends Component {
  render() {
    return (
      <div className="card">
      <div className="servingRoute">
      <div className="btnHolder">
      <button type="button" class="btn btn-success">Sign up</button>
<button type="button" class="btn btn-default">Login</button>
      </div>
        <h2 className="headingTitle">Serving the Route 422, 100, 202, and Route 30 Corridors</h2>
        <p className="servingContent">Archives with Hundreds of Useful Articles</p>
      </div>
      </div>
    );
  }
}

export default ServingRoute;
