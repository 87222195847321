import React, { Component } from "react";
import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer>
        <section className="container">
        <h3 className="text-center">Looking for <strong>digital issues?</strong><br />Visit <a href="http://www.422bizmag.com">www.422bizmag.com</a></h3>
        </section>
      </footer>
    );
  }
}

export default Footer;
